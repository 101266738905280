<script>
import { getBuffets, postReg, postRegNone, postRegNoneNew } from '../functions/api/content';

export default {
  async created() {
    window.localStorage.setItem('locale', this.$route.params.lang);
    this.$root.$i18n.locale = window.localStorage.getItem('locale');

    this.$store.commit('resetStore');
    this.$store.commit('setMvmId', this.$route.params.mvmId);
    await this.signupUser();

    getBuffets(this.$route.params.mvmId).then((result) => {
      if (result[0] && result[0].event !== undefined) {
        this.$store.commit('setShopType', 'preorder');
        return this.$router.push('/events');
      } else {
        this.$store.commit('setShopType', 'buffet');
        return this.$router.push('/sector-selector');
      }
    });
  },
  methods: {
    async signupUser() {
      return new Promise((resolve, reject) => {
        let type = 'none';
        let mvmId = this.$route.params.mvmId;
        let email = this.$route.params.email;
        let name = this.$route.params.name;
        let country = this.$route.params.country;
        let city = this.$route.params.city;
        let postalCode = this.$route.params.postalcode;
        let address = this.$route.params.address;
        let push_token = this.$route.params.push_token;
        const app = this.$route.params.app ?? '0';

        if (this.$route.params.premiumType !== undefined) {
          type = this.$route.params.premiumType;
        }

        if (type == 'none') {
          let fut;
          if (country != null && city != null && postalCode != null && address != null) {
            fut = postRegNoneNew(mvmId, email, name, country, city, postalCode, address, push_token, this.$store.getters['getSavedLocale']).then((result) => {
              this.$store.commit('setEmbedded', app);
              this.$store.commit('setMvmId', result.mvmId);
              this.$store.commit('setInvoiceData', {
                invoiceEmail: result.email,
                invoiceName: result.full_name,
                invoiceCountry: result.country,
                invoiceCity: result.city,
                invoicePostalCode: result.postalcode,
                invoiceAddress: result.address,
              });
              resolve();
            });
          } else {
            fut = postRegNone(mvmId, email, name, this.$store.getters['getSavedLocale']).then((result) => {
              this.$store.commit('setMvmId', result.mvmId);
              resolve();
            });
          }
          fut.catch((err) => {
            reject(err);
            console.log(err);
          });
        } else {
          postReg(mvmId, type, email, name, this.$store.getters['getSavedLocale'])
            .then((result) => {
              this.$store.commit('setMvmId', result.mvmId);
              resolve();
            })
            .catch((err) => {
              reject(err);
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
<template>
  <div></div>
</template>
