const getBuffetPage = (page) => () => import(/* webpackChunkName: "[request]" */ `./pages/buffet/${page}.vue`);
const getSkyboxPage = (page) => () => import(/* webpackChunkName: "[request]" */ `./pages/skybox/${page}.vue`);
const getPage = (page) => () => import(/* webpackChunkName: "[request]" */ `./pages/${page}.vue`);

import UserLoader from './components/UserLoader';

export const routes = [
  { path: '/auth/:token/:userId', component: getBuffetPage('splash'), name: 'splash', meta: { buffet: true } },
  { path: '/terms-conditions', component: getBuffetPage('terms-conditions'), name: 'terms-conditions' },
  { path: '/onboarding', component: getBuffetPage('onboarding'), name: 'onboarding', meta: { buffet: true } },
  { path: '/buffet-intro', component: getBuffetPage('buffet-intro'), name: 'buffet-intro', meta: { frame: ['menu', 'footer'], buffet: true } },
  {
    path: '/buffet-list',
    component: getBuffetPage('buffet-list'),
    name: 'buffet-list',
    meta: { frame: ['buffet-header', 'pending-orders', 'menu', 'footer'], buffet: true },
  },
  {
    path: '/sector-selector',
    component: getBuffetPage('sector-selector'),
    name: 'sector-selector',
    meta: { frame: ['buffet-header', 'pending-orders', 'menu', 'footer'], buffet: true },
  },
  {
    path: '/buffet-subpage',
    component: getBuffetPage('buffet-subpage'),
    name: 'buffet-subpage',
    meta: { frame: ['buffet-header', 'pending-orders', 'cart', 'menu', 'footer'], buffet: true },
  },
  {
    path: '/buffet-closed',
    component: getBuffetPage('buffet-closed'),
    name: 'buffet-closed',
    meta: { frame: ['buffet-header', 'menu', 'footer'], buffet: true },
  },
  { path: '/app-closed', component: getBuffetPage('app-closed'), name: 'app-closed' },
  { path: '/cart', component: getBuffetPage('cart'), name: 'cart', meta: { frame: ['buffet-header', 'menu', 'footer'], buffet: true } },
  { path: '/order-progress', component: getBuffetPage('order-progress'), name: 'order-progress-without-id', meta: { frame: ['menu', 'footer'], buffet: true } },
  { path: '/order-progress/:orderId', component: getBuffetPage('order-progress'), name: 'order-progress', meta: { frame: ['menu', 'footer'], buffet: true } },
  { path: '/order-history', component: getBuffetPage('order-history'), name: 'order-history', meta: { frame: ['menu', 'footer'], buffet: true } },
  { path: '/info-page/:id', component: getBuffetPage('info-page'), name: 'info-page', meta: { frame: ['menu', 'footer'], buffet: true } },
  { path: '/track-orders', component: getPage('track-orders'), name: 'track-orders', meta: { frame: ['buffet-header'], buffet: true } },

  //{path: '*', redirect: '/buffet-list'},

  //skybox routes
  { path: '/events', component: getSkyboxPage('events'), name: 'skybox-events', meta: { frame: ['skybox-header', 'pending-orders', 'footer'], buffet: false } },
  {
    path: '/event/:id',
    component: getSkyboxPage('event-view'),
    name: 'skybox-event',
    meta: { frame: ['skybox-header', 'pending-orders', 'cart', 'footer'], buffet: false },
  },
  { path: '/overview', component: getSkyboxPage('overview'), name: 'skybox-overview', meta: { frame: ['skybox-header', 'footer'], buffet: false } },
  {
    path: '/order-details',
    component: getSkyboxPage('order-details'),
    name: 'skybox-order-details',
    meta: { frame: ['skybox-header', 'footer'], buffet: false },
  },
  { path: '/error', component: getSkyboxPage('error'), name: 'skybox-error', meta: { frame: ['skybox-header', 'footer'], buffet: false } },

  // entry points
  {
    path: '/order/lang/:lang/premiumtype/:premiumType/mvmid/:mvmId/email/:email/name/:name',
    component: UserLoader,
    name: 'skybox-order',
    meta: { frame: ['footer'], buffet: false },
  },
  {
    path: '/order/lang/:lang/premiumtype/:premiumType/mvmid/:mvmId/email/:email/name/:name/country/:country/city/:city/postalcode/:postalcode/address/:address',
    component: UserLoader,
    name: 'skybox-order',
    meta: { frame: ['footer'], buffet: false },
  },
  {
    path: '/order/lang/:lang/premiumtype/:premiumType/mvmid/:mvmId/email/:email/name/:name/country/:country/city/:city/postalcode/:postalcode/address/:address/push_token/:push_token',
    component: UserLoader,
    name: 'skybox-order',
    meta: { frame: ['footer'], buffet: false },
  },
  {
    path: '/order/lang/:lang/premiumtype/:premiumType/mvmid/:mvmId/email/:email/name/:name/country/:country/city/:city/postalcode/:postalcode/address/:address/push_token/:push_token/app/:app/',
    component: UserLoader,
    name: 'skybox-order',
    meta: { frame: ['footer'], buffet: false },
  },
  {
    path: '/order/lang/:lang/mvmid/:mvmId/orderid/:orderId',
    component: getPage('order-result'),
    name: 'skybox-order-result',
    meta: { frame: ['skybox-header', 'footer'], buffet: false },
  },
  {
    path: '/postreg/lang/:lang/premiumtype/:premiumType/mvmid/:mvmId/email/:email/name/:name',
    component: getSkyboxPage('postreg'),
    name: 'skybox-postreg',
    meta: { frame: ['skybox-header', 'footer'], buffet: false },
  },
];
